import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Player } from 'video-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import "video-react/dist/video-react.css";
import './RecordingPlaybackModal.css'

const RecordingPlaybackModal = (props) => {
    const { isOpen, toggle, recording } = props

    const getModalSize = () => {
        // switch (recording.media_type.toLowerCase()) {
        //     case 'screen': return 'xl'
        //     default: return 'lg'
        // }
        return 'lg';
    }

    const modalBody = () => { 
        let currentPage = window.location.pathname;
        return <div>
            <audio
            autoPlay
            title={recording.recording_id}
            id={`audio_${recording.recording_id}`}
            controls            
            src={recording.url}
            className="custom-audio"></audio>
            </div>
    }
    return (
        <div>
            {recording &&
                (<Modal size={getModalSize()} isOpen={isOpen}>
                    <ModalHeader>Recording Playback - {recording && recording.recording_id}
                        <button onClick={() => { navigator.clipboard.writeText(recording.recording_id) }}><FontAwesomeIcon icon={faCopy} /></button>
                    </ModalHeader>
                    <ModalBody>
                        {modalBody()}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={() => { toggle() }}>Close</Button>
                    </ModalFooter>
                </Modal>)
            }
        </div>
    )
}

export default RecordingPlaybackModal