import React, { useState, useEffect, Fragment } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Row, Col } from 'reactstrap'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import backend from '../../services/backend'
import ReactTable from "react-table-6";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faFileVideo, faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons'
import RecordingPlaybackModal from '../Modals/recordingPlaybackModal/RecordingPlaybackModal'

import WaitingModal from '../Modals/waitingModal/WaitingModal'
import PCCRecordingProcessingModal from '../Modals/pccRecordingProcessingModal/PCCRecordingProcessingModal'
import AlertModal from '../Modals/alertModal/AlertModal'
import constants from '../../constants'
import moment from 'moment'

import './ConversationDetails.css'
import "react-table-6/react-table.css";
import utils from '../../services/utils';

const ConversationDetails = (props) => {
    const [error, setError] = useState()
    const [isWaitingModalOpen, setIsWaitingModalOpen] = useState(false)    
    const [isRecordingProcessingOpen, setIsRecordingProcessingOpen] = useState(false)
    const [conversation, setConversation] = useState()
    const [recordings, setRecordings] = useState([]);
    const [isRecordingPlaybackModalOpen, setIsRecordingPlaybackModalOpen] = useState(false)
    const [recordingPlayback, setRecordingPlayback] = useState()
    const [role, setRole] = useState()
    const history = useHistory()
    const { id } = useParams()
    const [isStillProcessing, setIsStillProcessing] = useState(true);

    useEffect(() => { // init load
        setRole(sessionStorage.getItem(constants.R2S_USER_ROLE));
        console.log('Conversation Details role is'+role);
        const fetchConversation = async () => {
            let response = null;
            try {
                setIsWaitingModalOpen(true)
                response = await backend.getPCCConversation(sessionStorage.getItem(constants.R2S_TOKEN), id)
                console.log('getPCCConversation.response:', response)                                
                setConversation(response);
            } catch (error) {
                console.log('fetchConversation:,', error)
                setError(`An error occured while fetching the conversation:${JSON.stringify(error.message)}`)
            } finally {
                setIsWaitingModalOpen(false)
            }           

            if(response && response.recordings){                 
                let recordings = response.recordings;
                let processedRecordings = [];
                setIsRecordingProcessingOpen(true);
                for(let recording of recordings){
                    if(recording.status!= 'COMPLETED'){
                        setIsStillProcessing(true);
                        console.log(`fetchProcessedRecordings:: Recording Response details ${JSON.stringify(recording)}`);
                        let apiCallSuccess = false
                        do{
                            let usertoken = sessionStorage.getItem(constants.R2S_TOKEN);        
                            let s3path = recording.relative_path.replace('wav','opus')        
                            try {
                                let apiResponse = await backend.getRecordingURLStatus(usertoken,s3path);
                                console.log(`fetchProcessedRecordings:: API response for recording URL Status is ${JSON.stringify(apiResponse)}`);   
                                if(apiResponse.convertionStatus && apiResponse.convertionStatus == 'COMPLETED'){
                                    apiCallSuccess = true; 
                                    recording.status = apiResponse.convertionStatus;
                                    recording.url = apiResponse.recURL;
                                }else{
                                    apiCallSuccess = false; 
                                }                                        
                            } catch (exception) {
                                console.log(`fetchProcessedRecordings::Got Exception in get recording URL Status`);
                                console.log(exception);
                                apiCallSuccess = false;
                            }
                        
                            if(apiCallSuccess== false){
                                console.log(`fetchProcessedRecordings:: will check recordings status after 10 seconds ${new Date()}`);
                                await sleepFor(10*1000);
                                console.log(`fetchProcessedRecordings:: will retry now ${new Date()}`);                                        
                            }
                        }while(apiCallSuccess == false);
                        processedRecordings.push(recording);
                    }else{
                        console.log(`recording.status is COMPLETED`);
                        processedRecordings.push(recording);
                    }                    
                }
                if(processedRecordings && processedRecordings.length>0){
                    setRecordings(processedRecordings);
                    setIsStillProcessing(false);                    
                }else{
                    console.log(`We dont have any processed recordings`);
                }                               
                setIsRecordingProcessingOpen(false);             
            }else{
                console.log(`convMetaDataresponse.recordings is undefined`);
            }
        }
        fetchConversation()
    }, [id])

    const handleRecordingClick = (recording) => {
        console.log('handleRecordingClick:', recording)
        setRecordingPlayback(recording)
        toggleRecordingPlaybackModal()
    }

    const toggleRecordingPlaybackModal = () => setIsRecordingPlaybackModalOpen(!isRecordingPlaybackModalOpen)

    const conditionallyRenderConversation = () => {
        if (!conversation) return

                return <div className="px-2">
                    <Row>
                        <Col>
                            <span className="details-header">Conversation Recordings</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ReactTable
                                showPageSizeOptions={true}
                                pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
                                defaultPageSize={10}
                                minRows={0}
                                className="-highlight -striped table-container"
                                data={recordings}
                                columns={[{
                                    Header: 'Recording Duration',
                                    accessor: 'recording_duration',
                                    headerClassName: 'headerColumn',
                                    Cell: row => {
                                        return (
                                            <div className="overflow div-center">
                                                {row.original.recording_duration && utils.formatMilliseconds(row.original.recording_duration)}
                                            </div>
                                        )
                                    }
                                }, {
                                    Header: 'Play',
                                    headerClassName: 'headerColumn',
                                    Cell: row => {                                        
                                        return (
                                            <div className="div-center">
                                                <button onClick={() => { handleRecordingClick(row.original) }}>
                                                    <FontAwesomeIcon icon={faPlay} />
                                                </button>
                                            </div>                                            
                                        )
                                    }
                                }
                                ]}
                                getTdProps={(state, rowInfo, column, instance) => {
                                    return {
                                        style: {
                                            cursor: 'pointer'
                                        }
                                    }
                                }}
                                getTrProps={() => {
                                    return {
                                        style: {
                                            flex: '0 0 auto'
                                        }
                                    }
                                }}
                                getTrGroupProps={() => {
                                    return {
                                        style: {
                                            flex: '0 0 auto'
                                        }
                                    }
                                }}
                                defaultFilterMethod={(filter, row) => {
                                    const id = filter.pivotId || filter.id;
                                    return (row[id] !== undefined ?
                                        String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
                                        : true
                                    );
                                }}
                            />
                        </Col>
                    </Row>
                </div>
        
    }

    return (
        <div id="component-wrap">
            <Header role={role} />
            <div id="main">
                <WaitingModal
                    isOpen={isWaitingModalOpen}
                    header='Loading conversation details'
                />
                
                <PCCRecordingProcessingModal
                    isOpen={isRecordingProcessingOpen}
                    header='Processing Recordings'
                />
                <AlertModal
                    isOpen={!!error}
                    header='Error'
                    toggle={() => { setError(undefined) }}
                    body={error}
                />

                <RecordingPlaybackModal
                    isOpen={isRecordingPlaybackModalOpen}
                    toggle={() => { toggleRecordingPlaybackModal() }}
                    recording={recordingPlayback}
                    role = {role}
                />

                <div className="px-2">
                    <Row>
                        <Col>

                            <button onClick={() => { history.push('/pccconversations') }}>
                                <FontAwesomeIcon className="fa-2x" icon={faChevronCircleLeft} />
                            </button>
                            <span className="conversations-details-header-text">Conversation Details - {conversation && conversation.conversation_id}</span>

                            <hr />
                        </Col>
                    </Row>
                    
                    <Row xs="2">
                        <Col>
                            <span className="details-header">Start Date</span><span className="details-value">{conversation && moment(conversation.conversation_start).format("MMM Do YYYY, h:mm:ss a")}</span>
                        </Col>
                        <Col>
                            <span className="details-header">End Date</span><span className="details-value">{conversation && moment(conversation.conversation_end).format("MMM Do YYYY, h:mm:ss a")}</span>
                        </Col>
                    </Row>

                    <Row xs="2">
                        <Col>
                            <span className="details-header">Agent</span><span className="details-value">{conversation && conversation.agent_name}</span>
                        </Col>
                        <Col>
                            <span className="details-header">Conversation Direction</span><span className="details-value">{conversation && conversation.direction}</span>
                        </Col>
                       
                    </Row>
                    
                    <Row xs="2">
                        <Col>
                            <span className="details-header">Queue</span><span className="details-value">{conversation && conversation.queue_name}</span>
                        </Col>
                        <Col>
                            <span className="details-header">Handle Duration</span><span className="details-value">{conversation && conversation.handle_duration && utils.formatMilliseconds(conversation.handle_duration)}</span>
                        </Col>
                    </Row>
                    
                    <Row xs="2">
                        <Col>
                            <span className="details-header">Talk & Hold</span><span className="details-value">{conversation && conversation.talk_duration && utils.formatMilliseconds(conversation.talk_duration)}</span>
                        </Col>
                        <Col>
                            <span className="details-header">ACW</span><span className="details-value">{conversation && conversation.acw_time && utils.formatMilliseconds(conversation.acw_time)}</span>
                        </Col>
                    </Row>                    
                    <Row>
                        <Col>
                            <hr />
                        </Col>
                    </Row>
                </div>
                {conditionallyRenderConversation()}
            </div>
            <Footer />
        </div>
    )
}
const sleepFor = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}
export default ConversationDetails