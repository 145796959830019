import React, { useState, useEffect, Fragment } from 'react'
import {MultiSelect} from "react-multi-select-component"
import Select from "react-select"
import {Button, InputGroup, InputGroupAddon, InputGroupText, Input, Label} from 'reactstrap'
import './UserDetails.css'
import backendAPI from '../../../../services/backend'
import AlertModal from '../../../Modals/alertModal/AlertModal'
import WaitingModal from '../../../Modals/waitingModal/WaitingModal'
import constants from '../../../../constants';


const UserDetails = (props) => {
    const [error, setError] = useState()
    const [isWaitingModalOpen, setIsWaitingModalOpen] = useState(true)

    const [dropDownsDisable,setDropDownsDisable] = useState(false)
    const [alertHeader, setAlertHeader] = useState("Error")

    const [userID, setUserID] = useState('')
    const [emailAddressEntered, setEmailAddressEntered] = useState('')
    const [firstNameEntered, setFirstNameEntered] = useState('')
    const [lastNameEntered, setLastNameEntered] = useState('')
    const [roleSelected, setRoleSelected] = useState({})
    const [divisionsSelected, setDivisionsSelected] = useState([])
    const [queuesSelected, setQueuesSelected] = useState([])
    const [stateSelected, setStateSelected] = useState(false)


    const [rolesList, setRolesList] = useState([])
    const [divisionsList, setDivisionsList] = useState([])
    const [queuesList, setQueuesList] = useState([])
    const [displayQueueList, setDisplayQueueList] = useState()
    const [sourceSelected, setsourceSelected] = useState([]);    
    const [pcQueuesList, setPcQueuesList] = useState([])
    const [pcQueuesSelected, setpcQueuesSelected] = useState([]);


    useEffect(() => {

        // const removeAll = (arrayToModify) => {
        //     const deleteIndex = arrayToModify.findIndex(obj => obj.value === "*")
        //     if ((deleteIndex > -1) && (arrayToModify.length > 1))
        //         arrayToModify.splice(deleteIndex, 1)
        //     return arrayToModify
        // }

        const replaceAll = (arrayToReplace) => {
            try {
                if ((arrayToReplace[0].value === "*")) {
                    return true
                }
                return false
            } catch (error) {
                return false
            }
        }
        

        const getUserDetails = async () => {
            try {
            const getUserMeResponse = await backendAPI.getUsersMe(sessionStorage.getItem(constants.R2S_TOKEN))
            setRolesList(getUserMeResponse.user.roles ? getUserMeResponse.user.roles.filter((x => x.value !== '*')) : [])
            setDivisionsList(getUserMeResponse.user.divisions ? getUserMeResponse.user.divisions.filter((x => x.value !== '*')) : [])
            setQueuesList(getUserMeResponse.user.queues ? getUserMeResponse.user.queues.filter((x => x.value !== '*')) : [])
            setPcQueuesList(getUserMeResponse.user.pcQueues ? getUserMeResponse.user.pcQueues.filter((x => x.value !== '*')) : [])
            sessionStorage.setItem('r2s-getUserMeResponse', JSON.stringify(getUserMeResponse))
            setRoleSelected(roleSelected.length>0 ? roleSelected : null)

            if (props.userId) {
                
                const getUserbyIDResponse = await backendAPI.getUserByID(sessionStorage.getItem(constants.R2S_TOKEN), props.userId);
                console.log(`Current Config for user is ${JSON.stringify(getUserbyIDResponse)}`);
                setUserID(props.userId);
                setEmailAddressEntered(getUserbyIDResponse.user.email);
                setFirstNameEntered(getUserbyIDResponse.user.firstName);
                setLastNameEntered(getUserbyIDResponse.user.lastName);
                setRoleSelected(getUserbyIDResponse.user.role);
                
                //authorizedDivisions
                let authorizedDivisions = getUserbyIDResponse.user.authorizedDivisions;
                let authorizedQueues = getUserbyIDResponse.user.authorizedQueues;
                console.log(`authorizedDivisions value ${JSON.stringify(authorizedDivisions)}`);
                console.log(`authorizedDivisions typeof ${typeof authorizedDivisions}`);
                console.log(`authorizedQueues value  ${JSON.stringify(authorizedQueues)}`);
                console.log(`authorizedQueues typeof ${typeof authorizedQueues}`);

                if(getUserbyIDResponse.user.source && getUserbyIDResponse.user.source === constants.CONVERSATION_SOURCE_ENGAGE){
                    setsourceSelected({"value": constants.CONVERSATION_SOURCE_ENGAGE,"label": "PCC"});
                }else if(getUserbyIDResponse.user.source && getUserbyIDResponse.user.source === constants.CONVERSATION_SOURCE_GC){
                    setsourceSelected({"value": constants.CONVERSATION_SOURCE_GC,"label": "Genesys Cloud"});                    
                }else if(getUserbyIDResponse.user.source && getUserbyIDResponse.user.source === '*'){
                    setsourceSelected({"value": '*',"label": "All"});                    
                }else{
                    setsourceSelected({"value": constants.CONVERSATION_SOURCE_GC,"label": "Genesys Cloud"});
                }

                setDivisionsSelected(getUserbyIDResponse.user.divisions.filter((x => authorizedDivisions.includes(x.value))));
                // if (replaceAll(getUserbyIDResponse.user.divisions)){
                //     setDivisionsSelected(getUserMeResponse.user.divisions.filter((x => x.value !== '*')));
                // }

                //authorizedQueues
                setQueuesSelected(getUserbyIDResponse.user.queues.filter((x => authorizedQueues.includes(x.value))));
                // if (replaceAll(getUserbyIDResponse.user.queues)){
                //     setQueuesSelected(getUserMeResponse.user.queues.filter((x => x.value !== '*')));
                // }
                if (getUserbyIDResponse.user.disabled){
                    setStateSelected(getUserbyIDResponse.user.disabled);
                }
                sessionStorage.setItem('r2s-getUserbyIDResponse', JSON.stringify(getUserbyIDResponse))
                if(getUserbyIDResponse.user.role.value === "admin"){
                    setDropDownsDisable(true);
                }else{
                    setDropDownsDisable(false);
                }

                if(getUserbyIDResponse.user.authorizedPCQueues){
                    let selectedPcQueue = getUserMeResponse.user.pcQueues.filter((x => getUserbyIDResponse.user.authorizedPCQueues.includes(x.value)));
                    if(getUserbyIDResponse.user.authorizedPCQueues.length == 1 && getUserbyIDResponse.user.authorizedPCQueues[0]== "*"){
                        selectedPcQueue = getUserMeResponse.user.pcQueues ? getUserMeResponse.user.pcQueues.filter((x => x.value !== '*')) : [];                    
                    }                
                    setpcQueuesSelected(selectedPcQueue?selectedPcQueue.filter((x => x.value !== '*')):[]);
                }
            }

        } catch (error) {
            setError(`An error occured while fetching data:${JSON.stringify(error.message)}`)
            console.log(error)
        } finally {
            setIsWaitingModalOpen(false)
        }

        }

        getUserDetails()

    }, [])
    
    const filterQueue =  (DivisionsInp,queueToFilter)=> {
        let selectedDivison = DivisionsInp.map( e => e.value)
        let queuesFilter = []
        let filteredQueue = []
        selectedDivison.forEach (element => {
            queuesFilter = queueToFilter.filter(x => x.divisionId === element)
            filteredQueue = filteredQueue.concat(queuesFilter)
        })
        return (filteredQueue)

    }

    const checkEmailValidation = (emailAddressEntered) =>{
        return /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(emailAddressEntered);        
    }

    return (
  
            <div className = "form-elements">
            <WaitingModal
            isOpen={isWaitingModalOpen}
            header='Loading User Details'
            />
             <AlertModal
            isOpen={!!error}
            header={alertHeader}
            toggle={() => { setError(undefined) }}
            body={error}
            />
            <br />
            <InputGroup className = "text-box">
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>Email Address <span className = "required-astrik"> * </span></InputGroupText>
                </InputGroupAddon>
                
                <Input
                    placeholder="Users's Email Address"
                    defaultValue={emailAddressEntered}
                    onChange={e => setEmailAddressEntered(e.target.value)}
                    disabled = {props.userId? true : false}
                />
            </InputGroup>

            <br />
            <InputGroup className = "text-box">
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>Firstname <span className = "required-astrik"> * </span></InputGroupText>
                </InputGroupAddon>
                <Input 
                    placeholder="User's Firstname"
                    defaultValue={firstNameEntered}
                    onChange={e => setFirstNameEntered(e.target.value)}
                />
            </InputGroup>
            <br />
            <InputGroup className = "text-box">
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>Lastname <span className = "required-astrik"> * </span></InputGroupText>
                </InputGroupAddon>
                <Input
                    placeholder="User's Lastname"
                    defaultValue={lastNameEntered}
                    onChange={e => setLastNameEntered(e.target.value)}
                />
            </InputGroup>
            <br />
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>Role <span className = "required-astrik"> * </span></InputGroupText>
                </InputGroupAddon>
                 <Select className = "single-select"
                       placeholder = "Select..."
                        options={rolesList}
                        value={roleSelected}
                        onChange={e => { setRoleSelected(e) 
                            if(e.value === "admin"){
                                setDropDownsDisable(true)
                                setDivisionsSelected(divisionsList)
                                setQueuesSelected(queuesList)

                                setpcQueuesSelected(pcQueuesList);                                
                            }
                            else{
                                setDropDownsDisable(false)
                                setDivisionsSelected([])
                                setQueuesSelected([])
                                setpcQueuesSelected([]);
                            }
                            setsourceSelected({"value": "*","label": "All"});

                             }}
                    />
            </InputGroup>
            <br />
            {roleSelected && roleSelected.value !='admin'?(            
            <div><InputGroup>
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>Source<span className = "required-astrik"> * </span></InputGroupText>
                </InputGroupAddon>
                <Select className="single-select"
                    options={[{"value": "*","label": "All"},{"value": constants.CONVERSATION_SOURCE_GC,"label": "Genesys Cloud"},{"value": constants.CONVERSATION_SOURCE_ENGAGE,"label": "PCC"}]}
                    value={sourceSelected}
                    disabled = {dropDownsDisable}
                    onChange={e => {setsourceSelected(e)
                        setDivisionsSelected([])
                        setQueuesSelected([])
                        
                        setpcQueuesSelected([])
                    }}
                    labelledBy={constants.CONVERSATION_SOURCE_GC}
                />
            </InputGroup>
            <br /></div>):''}
            
            {((roleSelected && roleSelected.value =='admin') || sourceSelected.value=='*' || sourceSelected.value==constants.CONVERSATION_SOURCE_GC)?(  
            <div>
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>Division<span className = "required-astrik"> * </span></InputGroupText>
                </InputGroupAddon>
                <MultiSelect className="multi-drop-down"
                    options={divisionsList}
                    value={divisionsSelected}
                    disabled = {dropDownsDisable}
                    onChange={e => {setDivisionsSelected(e)
                                    const selectedDivison = e;
                                    setDisplayQueueList(filterQueue(selectedDivison,queuesList))
                                    setQueuesSelected(filterQueue(selectedDivison,queuesSelected))
                                    }}
                    labelledBy="Select"
                />
            </InputGroup>
            <br />
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>Queues<span className = "required-astrik"> * </span></InputGroupText>
                </InputGroupAddon>
                <MultiSelect className="multi-drop-down"
                    options={displayQueueList ? displayQueueList : 
                            (filterQueue(divisionsSelected,queuesList).length>0 ? filterQueue(divisionsSelected,queuesList) : queuesList)}
                    value={queuesSelected}
                    disabled = {dropDownsDisable}
                    onChange={e => setQueuesSelected(e)}
                    labelledBy="Select"
                />
            </InputGroup>
            <br />
            </div>
            ):''}

            {((roleSelected && roleSelected.value =='admin') || sourceSelected.value=='*' || sourceSelected.value==constants.CONVERSATION_SOURCE_ENGAGE)?(
                <div>                    
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>PCC Queues<span className = "required-astrik"> * </span></InputGroupText>
                        </InputGroupAddon>
                        <MultiSelect className="multi-drop-down"
                            options={pcQueuesList}
                            value={pcQueuesSelected}
                            disabled = {dropDownsDisable}
                            onChange={e => setpcQueuesSelected(e)}
                            labelledBy="Select"
                        />
                    </InputGroup>
                    <br />
                </div>):''}

            <Input addon type="checkbox" onChange={e => setStateSelected(e.target.checked)} checked={stateSelected} />
            <Label>&nbsp; Disable </Label>
            <br />
            <br />
                                    
            <Button color="primary" onClick={() => {
            
            if(sourceSelected && sourceSelected.value == constants.CONVERSATION_SOURCE_GC){
                if (!emailAddressEntered || !firstNameEntered || !lastNameEntered  || !roleSelected || !divisionsSelected || !queuesSelected                    
                    || (roleSelected && roleSelected.length === 0) 
                    || (divisionsSelected && divisionsSelected.length === 0) 
                    || (queuesSelected && queuesSelected.length === 0)
                    ) {
                    setError(`Please enter all the required fields before submitting`)
                    setAlertHeader("Validation Error")
                    return
                }
            }else if(sourceSelected && sourceSelected.value ==constants.CONVERSATION_SOURCE_ENGAGE){
                if (!emailAddressEntered || !firstNameEntered || !lastNameEntered || !roleSelected  || !pcQueuesSelected                    
                    || (roleSelected && roleSelected.length === 0)                     
                    || (pcQueuesSelected && pcQueuesSelected.length === 0)
                    ) {
                    setError(`Please enter all the required fields before submitting`)
                    setAlertHeader("Validation Error")
                    return
                }
            }else if(sourceSelected && sourceSelected.value =='*'){    
                if (!emailAddressEntered || !firstNameEntered || !lastNameEntered 
                    || (roleSelected && roleSelected.length === 0) || (divisionsSelected && divisionsSelected.length === 0) || (queuesSelected && queuesSelected.length === 0)) {
                    setError(`Please enter all the required fields before submitting`)
                    setAlertHeader("Validation Error")
                    return
                }
            }

            if(!checkEmailValidation(emailAddressEntered)){
                setError(`Please enter a valid email address`)
                setAlertHeader("Validation Error")
                return
            }

                props.submit({
                emailAddressEntered,
                firstNameEntered,
                lastNameEntered,
                roleSelected: rolesList.length === roleSelected.length ? [''] : roleSelected,
                stateSelected: stateSelected,
                source: sourceSelected.value,
                divisionsSelected: divisionsList.length === divisionsSelected.length ? [{
                    "value": "*",
                    "label": "All"
                }] : divisionsSelected,
                queuesSelected: queuesList.length === queuesSelected.length ? [{
                    "value": "*",
                    "label": "All"
                }] : queuesSelected,
                pcQueuesSelected: pcQueuesList.length === pcQueuesSelected.length ? [{
                    "value": "*",
                    "label": "All"
                }] : pcQueuesSelected,
                userid: userID
            })}}>{props.okButtonText ? props.okButtonText : 'OK'} </Button>
            &nbsp;&nbsp;
            <Button color="secondary" disabled={props.loading? true : false} onClick={() => props.cancel()}>{props.cancelButtonText ? props.cancelButtonText : 'Cancel'}</Button>
            <br />
            <br />
                    </div>
            
    )      
}

export default UserDetails